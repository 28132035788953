
.current-staff-wrap {
  & table.table {
    width: auto;
    & td {
      border-bottom: none;
    }
  }
}
.select-wrap flex-shrink-0.account {
  width: 140px;
}
