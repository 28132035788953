
.delete-form {
  background-color: var(--bs-gray-300);
  & .multiselect__content-wrapper {
    width: 280px!important;
  }
  & ul>li {
    list-style-type: disc;
}
}


