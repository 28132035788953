
.card {
  margin-bottom: 0 !important;
}
.select-wrap {
    width: 100%;
}
.map-gradient {
    background: linear-gradient(to right, var(--logo-green), #499FAF);
}
.light-signal {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    &.R {
        background-color: var(--bs-red);
    }
    &.Y {
        background-color: var(--bs-yellow);
    }
    &.G {
        background-color: var(--logo-green);
    }
    &.B {
        background-color: var(--bs-blue);
    }
}
.count-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    clip-path: inset(0 20px 0 0);
    & .inner-icon {
        position: absolute;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
    }
    &.r {
        background: linear-gradient(to bottom right, rgba(247, 49, 100, 0.2), #fff);
    }
    &.g {
        background: linear-gradient(to bottom right, rgba(8, 178, 88, 0.2), #fff);
    }
    &.y {
        background: linear-gradient(to bottom right, rgba(255, 193, 7, 0.2), #fff);
    }
    &.b {
        background: linear-gradient(to bottom right, rgba(13, 110, 253, 0.2), #fff);
    }
}
.data-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    &.bg-n-activated {
        background-color: #d2ede0;
    }
    &.bg-activated {
        background-color: var(--logo-green);
    }
  }
.overall-card {
  min-width: 205px;
  display: flex;
  padding: 15px;
  border-radius: 5px;
  gap: 15px;
}
// .light-card-wrap {
//     width: 205px;
// }
.svg-box {
    width: 43px;
    height: 43px;
    background: #fff;
    box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.firmware-overflow {
    overflow: auto;
    max-height: 290px;
}
.firmware-icon {
  width: 35px;
  height: 35px;
  background-color: var(--logo-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
