
.submenu-box {
  margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 17px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}
.submenu-box .checkbox::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 15px;
  vertical-align: middle;
}
