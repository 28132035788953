:root {
  --theme-deafult: #13a89e !important;
  --theme-third: #0d6efd !important;
  --light-gray: #adb5bd ;
  --logo-green: #08B258;
}
$primary-color: #13a89e !important;

.cursor-p {
  cursor: pointer;
}
.hover-o8:hover {
  opacity: 0.8;
}
.fs-sm{
  font-size: 12px
}
.fs-16{
  font-size: 16px
}
.img-40 {
  height: 40px;
  width: 40px;
  object-fit: cover;
  object-position: center;
}
.img-50 {
  height: 50px;
  width: 50px;
  object-fit: cover;
  object-position: center;
}
.sort-arrow {
  color: var(--light-gray);
  &:hover,&:focus,&.active {
    color: #000;
  }
}
.btn-third {
  border-color: var(--theme-third);
  color: #fff;
  background-color: var(--theme-third);
  &:hover,&:focus,&:active,&.active {
    color: var(--theme-third);
    background-color: #fff;
    border-color: var(--theme-third);
  }
}
@media only screen and (min-width: 768px) {
  .map-sidebarh {
    min-height: 1000px;
    display: block;
  }
}
@media only screen and (min-width: 1500px) {
  .col-xxxl-6 {
      flex: 0 0 auto;
      width: 50%;
  }
}
.search-box {
  // width: 170px;
  & .vue-feather--search {
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
  }
  & input {
    padding-left: 40px !important;
  }
}
.custom-audio {
   width: 20px;
   height: 20px;
   border-radius: 50%;
   background-color: var(--theme-deafult);
   position: relative;
   margin-inline: auto;
   cursor: pointer;
   & .triangle {
      width: 0;
      height: 0;
      border-top: 10px solid #fff;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: rotate(-90deg);
      position: absolute;
      top: 5px;
      left: 5px
   }
}
#audioModal .modal-content {
   background-color: transparent;
   align-items: center;
}
.checkbox-primary.checkbox-center label::before {
  top: 50%;
  transform: translateY(-50%);
}
p, span {
  word-break: break-all;
}
.text-logo-green {
  color: var(--logo-green);
}
.clear-btn:hover {
    opacity: 0.8;
}
.single-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.td-width {
    max-width: 500px;
  }
}
// .content-box {
//   @media only screen and (min-width: 768px) {
//     max-height: 800px;
//     overflow: auto;
//   }
// }