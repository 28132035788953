// 調整原本 style
.modal-backdrop.show {
  opacity: 0.3;
}
.modal {
  & .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 4rem);
  }
  & .modal-header {
    border-bottom: none;
  }
  & .modal-footer {
    border-top: none;
  }
}
.multiselect__option--highlight {
  background: var( --theme-deafult) !important;
}
.card {
  border-radius: 5px;
  & .card-header {
    padding: 15px;
  }
  & .card-body {
    padding: 15px;
  }
}
table.table {
  text-wrap: nowrap;
  & td {
    color: var(--bs-secondary);
  }
  & .form-switch .form-check-input {
    width: 40px;
  }
  & .form-check-input {
    height: 20px;
  }
}
.btn-outline-primary {
  border-color: var(--theme-deafult);
  color: var(--theme-deafult);
  background-color: transparent;
  &:hover,&:focus,&:active,&.active {
    color: white;
    background-color:var(--theme-deafult) !important;
    border-color: var(--theme-deafult) !important;
  }
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
}
input::placeholder, textarea::placeholder {
  color: #ADADAD!important
}
.material-design-icon__svg {
  display: block;
}
.border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item 
.nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus {
  &.txt-primary {
    border-bottom: 2px solid var(--theme-deafult);
  }
   &.text-warning {
    border-bottom: 2px solid var(--bs-warning);
  }
   &.text-primary {
    border-bottom: 2px solid var(--bs-primary);
  }
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links > li.sidebar-list:hover > a:hover {
    background-color: rgba(20, 161, 177, 0.07);
  }
#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: rgba(20, 161, 177, 0.15)!important;
}
.checkbox label {
  margin-top: 8px;
  margin-bottom: 8px;
}
.ql-editor {
  max-height: 250px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  padding-bottom: 10px;
  // height: 2000px;
}
[class*=-gradien]:hover{
  background-size: 100% !important;
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background-color: var(--bs-gray-300) !important;
}

@media only screen and (max-width: 575.98px) {
    .no-res-tab .border-tab.nav-tabs {
        display: flex;
    }
}