
  // .select-wrap.role-wrap {
  //   width: 180px;
  // }
  .submenu-box {
    margin-left: 6px;
}
.submenu-box::before {
  content: '';
  width: 1px;
  height: calc(100% - 11px);
  background-color: var(--bs-gray-500);
  position: absolute;
  left: 0;
  top: 0;
}
.submenu-box p::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 1px;
  background-color: var(--bs-gray-500);
  margin-right: 10px;
  vertical-align: middle;
}
